import { Preferences } from '@capacitor/preferences';

export var text : TextImport;
export var Country : Country;

export var global_lang = 'FR';

export async function clearLocal() {
  await Preferences.clear();
}
export async function storeLocal(key, value) {
//console.log('storing,', key, value);
await Preferences.set({
  key: key,
  value: JSON.stringify(value)
  });
//  return ret;
}

export async function getLocal(key) {
const f = await Preferences.get({
    key: key
});
if (JSON.parse(f.value)) return JSON.parse(f.value);
//console.log('get local', f.value);
  return (f.value);
}

export async function getIpInfo(refresh: Boolean)  {
      var url = 'https://api.ipdata.co/?api-key=816709c2dcb6031cd73fa5af1986787378f4f8fbe0c0077039435d6d&refresh=' + refresh;
    const j =  await fetch(url, { method: 'GET' }).then((d) => {
         const j = d.json();
         return j;
      });

        var cname, tname = "";
        if (j.carrier) cname = j.carrier.name;
        if (j.time_zone) tname = j.time_zone.name;

        const network = {
            carrier: cname,
            ip: j.ip,
            asn: j.asn ? j.asn['asn'] : '',
            domain: j.asn ? j.asn['domain'] : '',
            name: j.asn ? j.asn['name'] : ''
        }

        const v = {
          carrier: <string>cname,
          network: <object>network,
          timezone: <string>tname,
          code: <string>j.country_code
        }

        console.log(v);
        return (v);

}


export async function setLang(lang: string) {
if (!lang) {
if (!global_lang){
let local_lang = await getLocal('lang');
if (!local_lang) {
  var userLang = (navigator.language);
  const nlang = (userLang.split('-')[0]).toUpperCase();
  console.log('lang from browser', nlang);
  lang = nlang;
}
else {
  lang = local_lang;
  console.log('found lang in local lang')
}
}
else {
  lang = global_lang;
  console.log('could find lang in global lang')
}
}
else {
  console.log('there was a lang set', lang);
}

let url = '/assets/lang/' + lang + '.json';
return fetch(url, { method: 'GET' }).then((d) => {
  return d.json();
}).then((d) => {
  console.log('lang was fetched/set', lang);
  storeLocal('lang', lang);
  global_lang = lang;
  text = d;
  return d;
}).catch(()=>{
console.log(lang+' was not found in JSON');
  return setLang('EN');
});

}
